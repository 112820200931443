import request from '@/utils/request'


// 查询优惠券-最小金额和减免金额列表
export function listCouponMoney(query) {
  return request({
    url: '/platform/coupon-money/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券-最小金额和减免金额分页
export function pageCouponMoney(query) {
  return request({
    url: '/platform/coupon-money/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券-最小金额和减免金额详细
export function getCouponMoney(data) {
  return request({
    url: '/platform/coupon-money/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券-最小金额和减免金额
export function addCouponMoney(data) {
  return request({
    url: '/platform/coupon-money/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券-最小金额和减免金额
export function updateCouponMoney(data) {
  return request({
    url: '/platform/coupon-money/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券-最小金额和减免金额
export function delCouponMoney(data) {
  return request({
    url: '/platform/coupon-money/delete',
    method: 'post',
    data: data
  })
}
